<template>
  <Portlet
    title="StartFinish Details"
    icon="play"
    class="startFinishDetails"
  >
    <template slot="buttons">
      <a
        href="#"
        m-portlet-tool="reload"
        class="m-portlet__nav-link m-portlet__nav-link--icon reloadButton"
        @click.prevent="reloadEverything()"
      >
        <font-awesome-icon
          :class="['alt-pointer color-primary', { 'fa-spin' : loading || reloading}]"
          icon="sync-alt"
          style="vertical-align: middle;"
        />
      </a>
    </template>

    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="row mt-2">
        <div class="col-12 col-sm-6">
          <template v-if="displayStatus">
            <h4 class="m-0 p-0 mb-1 d-inline-block">
              {{ $t('displayStatus') }}
            </h4>
            <hr class="m-0 p-0 mb-4 pb-1">
            <table class="defaultTable">
              <thead>
                <tr>
                  <th>{{ $t('startDisplay') }}</th>
                  <th>{{ $t('finishDisplay') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="badge badge-pill badge-info">{{ displayStatus.startDisplay }}</span>
                  </td>
                  <td>
                    <span class="badge badge-pill badge-info">{{ displayStatus.finishDisplay }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>

        <div class="col-12 col-sm-6">
          <template v-if="triggerReport">
            <h4 class="m-0 p-0 mb-1 d-inline-block">
              Odysseus Trigger Events
            </h4>
            <hr class="m-0 p-0 mb-4 pb-1">
            <table class="defaultTable">
              <colgroup>
                <col width="75">
              </colgroup>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Flapping Trigger</th>
                  <th>Active Events out of last 10</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="border-top">
                    Start
                  </th>
                  <td>{{ triggerReport.start.mistake }}</td>
                  <td>{{ triggerReport.start.activeCount }}</td>
                </tr>
                <tr>
                  <th>Finish</th>
                  <td>{{ triggerReport.finish.mistake }}</td>
                  <td>{{ triggerReport.finish.activeCount }}</td>
                </tr>
              </tbody>
            </table>
            <p>
              If "Active Events out of last 10" is 10, then finish trigger is not adjusted or the start trigger is always open (max 2 are ok).
              <br>
              "Flapping Trigger" means that we recieved more then 3 active trigger events in the last 10 events.
            </p>
          </template>
        </div>
        <a
          :class="`badge badge-pill badge-success float-left p-3`"
          disabled
        >
          <span>{{ currentState }}</span>
        </a>
      </div>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: 'StartFinishDetails',
  mixins: [
    dateTimeMixin,
    kendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
     laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      displayStatus: null,
      triggerReport: null,
      reloading: false,
      currentState: null,
      currentStateInterval: null,
      cancelSource: null,
      kgm_sort: [
        {
          "field": "processDate",
          "dir": "desc"
        }
      ],
      kgm_columns: [
        {
          field: 'processDate',
          filterable: false,
          filter: 'date',
          cell: 'dateTemplate',
          title: 'ProcessDate',
          width: '140px'
        },
        {
          field: 'topic',
          filterable: false,
          title: 'Topic',
          width: '110px',
          media: 'sm'
        },
        {
          field: 'message',
          filterable: false,
          title: 'Message'
        }
      ]
    }
  },
  created () {
    this.createInterval();
    this.getTriggerReport();
    this.getDisplayStatus();
  },
  methods: {
    reloadEverything () {
      this.getTriggerReport();
      this.getDisplayStatus();
    },

//#region API-calls
    getDisplayStatus () {
        let url = `/StartFinish/DisplayStatus?installationId=${ this.installationId }`; 
        if (this.laneNumber)
        {
          url += `&laneNumber=${ this.laneNumber }`;
        }
        this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }

          this.displayStatus = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTriggerReport () {
        let url = `/StartFinish/TriggerReport?installationId=${ this.installationId }`; 
        if (this.laneNumber)
        {
          url += `&laneNumber=${ this.laneNumber }`;
        }
        this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }

          this.triggerReport = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelInterval () {
      window.clearInterval(this.currentStateInterval);
      this.currentStateInterval = null;
    },
    createInterval () {
      this.currentStateInterval = window.setInterval(() => {
        this.reloadEverything();
        this.getCurrentState();
      }, 1000);
    },
     cancelRequest () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
    },
    getCurrentState () {
      this.cancelRequest();
      this.cancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelSource.token };
      let url = `/StartFinish/CurrentState?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.cancelSource = null;
          this.currentState = response.data.sessionState;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
          this.cancelInterval();
        })
    },
//#endregion
  }
}
</script>

<style scoped>
.badge.badge-pill.badge-info {
  font-size: 1.25rem;
}
</style>
